:root {
  --size: calc(1vh + 1vw);
}
body {
  font-family: "Montserrat";
}

.navbar {
  background: #ff0014;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(15px);
  padding: 0.5% 10%;
  width: 100%;
  position: fixed;
  z-index: 1001;
}
.navbar-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.nav-logo {
  width: 15%;
}
.nav-a {
  text-decoration: none;
  color: #fff;
  cursor: pointer;
  font-size: calc(var(--size) / 1.3);
  margin-right: 15px;
  font-weight: 400;
  scale: 1;
  transition: all 0.3s;
}
.nav-a:hover {
  transition: all 0.3s;
  opacity: 0.5;
  color: #fff;
}
.welcome-block {
  background-image: url(./img/welcome-back.png);
  background-size: cover;
  height: 100vh;
  display: grid;
  place-items: center;
}
.welcome-text {
  display: grid;
  place-items: center;
}
.welcome-h5 {
  color: #ff0014;
  font-size: calc(var(--size) / 1.2);
  text-transform: uppercase;
  font-weight: 600;
}
.welcome-h2 {
  color: #fff;
  font-weight: 800;
  font-size: calc(var(--size) * 2);
}
.welcome-p {
  color: #fff;
  font-size: calc(var(--size) / 1.1);
  margin-top: 2%;
}
.welcome-btn {
  margin-top: 1%;
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 5px;
  color: #fff;
  font-size: calc(var(--size));
  padding: 1% 5%;
  scale: 1;
  transition: all 0.3s;
  text-decoration: none;
}
.welcome-btn:hover {
  background-color: #fff;
  color: #000;
  transition: all 0.3s;
}
.whyUs-block {
  display: grid;
  place-items: center;
  padding: 5% 5%;
}
.whyUs-row {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 4%;
  margin-bottom: 4%;
}
.whyUs-arrow {
  width: 5%;
}
.whyUs-h {
  font-size: calc(var(--size) * 1.4);
  font-weight: 500;
}
.whyUs-row-h {
  font-weight: 700;
  font-size: calc(var(--size) * 2.5);
}
.whyUs-row-p {
  font-size: calc(var(--size) / 1.2);
}
.whyUs-foot-p {
  font-size: calc(var(--size) / 1.5);
  max-width: 750px;
  padding-bottom: 2%;
  border-bottom: 1px solid grey;
}
/* Custom styles for the slide container */
.slide-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0; /* Set a background color for the slides */
  border-radius: 8px; /* Optional: Add rounded corners to the slides */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: Add a box shadow */
}

/* Custom styles for the central slide (active) */
.swiper-slide-active .slide-container {
  width: 100%;
  height: 100%;
  transform: scale(1);
  transition: all 0.3s ease;
}
.swiper-mat img {
  margin-bottom: 13%;
}
/* Custom styles for the neighboring slides */
.swiper-slide-next .slide-container,
.swiper-slide-prev .slide-container {
  width: 60%; /* Adjust the size of the smaller slides as needed */
  height: 80%; /* Adjust the size of the smaller slides as needed */
  transform: scale(0.8); /* Adjust the size of the smaller slides as needed */
  transition: all 0.3s ease;
}
.swiper-pagination-bullet {
  background-color: #ff0000; /* Replace #ff0000 with your desired color */
  opacity: 1;
}

/* Custom styles for active pagination bullet */
.swiper-pagination-bullet-active {
  background-color: #00ff00; /* Replace #00ff00 with your desired color for active bullet */
}
.contact-block {
  display: grid;
  place-items: center;
  padding: 5% 5%;
}
.contact-row {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 4%;
}
.contact-row-form {
  max-width: 400px;
}
.form-wrap {
  display: grid;
  place-items: center;
}
.contact-row-h img {
  width: 100%;
}
.contact-form-btn {
  border-radius: 7.037px;
  border: 1px solid #ff0014;
  background: #ff0014;
  color: #fff;
  width: 50%;
  padding: 1.5% 3%;
  scale: 1;
  transition: all 0.3s;
  font-size: calc(var(--size) / 1.4);
}
.contact-form-btn:hover {
  background-color: #fff;
  color: #000;
  border: 1px solid #ff0014;
  transition: all 0.3s;
}
.contact-h {
  font-size: calc(var(--size) * 1.4);
  font-weight: 500;
}
.contact-input {
  width: 100%;
  border-radius: 7px;
  border: 2px solid #181818;
  background-color: transparent;
  margin-bottom: 3%;
  padding: 1.5% 2%;
}
.contact-input::placeholder {
  font-size: calc(var(--size) / 1.5);
  color: #181818;
}
.contact-row-h {
  font-weight: 700;
  font-size: calc(var(--size) * 2);
}
.contact-or {
  margin-top: 5%;
  font-size: calc(var(--size) * 1.4);
}

.contact-row-cont {
  display: flex;
  justify-content: center;
  margin-top: 3%;
}
.contacts {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.contacts-p-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 15%;
}
.contacts-icon {
  width: 5%;
}
.contacts-h {
  font-weight: 700;
  font-size: calc(var(--size) * 2);
}
.contacts-h5 {
  font-weight: 800;
  font-size: calc(var(--size) / 1.1);
}
.kovel-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.kovel-wrap-icon {
  display: flex;
  align-items: center;
}
.rect {
  width: 20%;
}
.margin-top {
  margin-top: 5%;
}
.footer {
  background-color: #181818;
  width: 100%;
  padding: 0.5% 0;
  display: grid;
  place-items: center;
}
.footer-p {
  color: #fff;
  font-size: calc(var(--size) / 1.6);
  font-weight: 300;
  padding-top: 0.5%;
}
.footer-p a {
  color: #fff;
}
.toTop {
  width: 5%;
  position: fixed;
  bottom: 1%;
  right: 1%;
  cursor: pointer;
  scale: 1;
  transition: all 0.3s;
  display: block;
  z-index: 1001;
  transform: translateY(100px);
  transition: all 0.3s;
}
.toTop:hover {
  opacity: 0.7;
  transition: all 0.3s;
}
#single {
  width: 50%;
}
#three {
  width: 30%;
}
#two {
  width: 30%;
}
.c-img {
  display: grid;
  place-items: center;
}
.ask {
  width: 5%;
  position: fixed;
  bottom: 1%;
  left: 1%;
  scale: 1;
  transition: all 0.3s;
  cursor: pointer;
  display: block;
  z-index: 1001;
  transform: translateY(100px);
  transition: all 0.3s;
}
.icon-show {
  transform: translateY(0px);
  transition: all 0.3s;
}
.anim {
  animation: shake 2s infinite;
}
@keyframes shake {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(15px);
  }
  100% {
    transform: translateY(0px);
  }
}
.ask:hover {
  opacity: 0.7;
  transition: all 0.3s;
}
.modal-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-open-btn {
  padding: 10px 20px;
  background-color: #ccc;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}
.form-btn {
  border-radius: 3px;
  background: #ff0014;
  color: #000;
  border: 1px solid #ff0014;
  font-size: calc(var(--size) / 1.4);
  padding: 1.5% 10%;
  font-weight: 400;
  scale: 1;
  transition: all 0.3s;
  width: 50%;
}
.form-btn:hover {
  background-color: #fff;
  color: #222;
  transition: all 0.3s;
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modal-content {
  position: relative;
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  max-width: 500px;
  display: grid;
  place-items: center;
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
}

.modal-h {
  font-size: calc(var(--size) * 1.1);
  margin-top: 0;
}

.modal-text {
  margin-bottom: 20px;
  font-size: calc(var(--size) / 1.5);
  max-width: 320px;
}

.modal-form {
  display: flex;
  flex-direction: column;
  width: 75%;
  max-width: 320px;
  align-items: center;
  justify-content: center;
}

.modal-tel-input {
  padding: 1.5% 5%;
  margin-bottom: 10px;
  width: 100%;
}
.modal-tel-input::placeholder {
  font-size: calc(var(--size) / 1.6);
}
.modal-open {
  overflow: hidden;
}

.modal-open .modal-wrapper {
  pointer-events: none;
}

.modal-open .modal-overlay {
  pointer-events: auto;
}

.modal-open .modal-overlay .modal-content {
  transform: translateY(0%);
}
.modal-content {
  position: relative;
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  max-width: 500px;
  opacity: 0;
  transform: translateY(-50%);
  animation: modalFadeIn 0.3s ease forwards;
}
.contact-hs {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2%;
  margin-top: 5%;
}
.contacts-h {
  display: grid;
  place-items: center;
}
.kovel-wrap-icon {
  justify-content: center;
}
.contacts-tels {
  display: flex;
  justify-content: center;
  align-items: center;
}
#p {
  display: flex;
  align-items: center;
}
@keyframes modalFadeIn {
  from {
    opacity: 0;
    transform: translateY(-50%);
  }
  to {
    opacity: 1;
    transform: translateY(0%);
  }
}

.modal-closing .modal-content {
  animation: modalFadeOut 0.3s ease forwards;
}
.modal-close:hover {
  color: red;
  transition: all 0.3s;
}

@keyframes modalFadeOut {
  from {
    opacity: 1;
    transform: translateY(0%);
  }
  to {
    opacity: 0;
    transform: translateY(-50%);
  }
}
/* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #bfbfbf;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #a5a5a5;
}

.preloader {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: #e0e0e0;
  z-index: 1001;
}

.preloader__row {
  position: relative;
  top: 50%;
  left: 50%;
  width: 70px;
  height: 70px;
  margin-top: -35px;
  margin-left: -35px;
  text-align: center;
  animation: preloader-rotate 2s infinite linear;
}

.preloader__item {
  position: absolute;
  display: inline-block;
  top: 0;
  background-color: grey;
  border-radius: 100%;
  width: 35px;
  height: 35px;
  animation: preloader-bounce 2s infinite ease-in-out;
}

.preloader__item:last-child {
  top: auto;
  bottom: 0;
  animation-delay: -1s;
}

@keyframes preloader-rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes preloader-bounce {
  0%,
  100% {
    transform: scale(0);
  }

  50% {
    transform: scale(1);
  }
}

.loaded_hiding .preloader {
  transition: 0.3s opacity;
  opacity: 0;
}

.loaded .preloader {
  display: none;
}
.swiper-container {
  width: 100%;
  max-width: 800px;
  height: 300px;
  margin: 0 auto;
}

.swiper-slide {
  transition: transform 0.3s ease;
  transform-origin: center center;
}

.swiper-slide:not(.swiper-slide-active) {
  transform: scale(0.5);
  opacity: 1;
}

.swiper-slide-active {
  transform: scale(1);
  opacity: 1;
}

.swiper-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.materials-block {
  padding: 5% 5%;
}
.swiper-slide {
  height: auto !important;
  padding-bottom: 2%;
}
#taras {
  margin-top: 15%;
}
.t {
  width: 100%;
}
.tr {
  width: 85%;
}
.material-h {
  font-size: calc(var(--size) * 1.4);
  font-weight: 500;
  margin-bottom: 5%;
}
.material-p {
  font-size: calc(var(--size) / 1.5);
  max-width: 750px;
  padding-bottom: 2%;
  border-bottom: 1px solid grey;
  margin-top: 5%;
}
.mat-wrap {
  display: grid;
  place-items: center;
}
.prod-block {
  display: grid;
  place-items: center;
  padding: 3% 5%;
}

.prod-h {
  font-size: calc(var(--size) * 1.4);
  font-weight: 500;
  margin-bottom: 5%;
}

.prod-row {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5%;
}

.flex-row {
  display: flex;
}

.f-img {
  width: 30.5%;
  margin-right: 1%;
  scale: 1;
  transition: all 0.3s;
  position: relative; /* Set position relative to allow overlay positioning */
}

.f-img:hover {
  opacity: 0.5;
  transition: all 0.3s;
}

.img-24 {
  width: 88.5%;
  margin-right: 3%;
  margin-bottom: 3%;
  scale: 1;
  transition: all 0.3s;
  position: relative; /* Set position relative to allow overlay positioning */
}

.img-35 {
  width: 88.5%;
  margin-right: 3%;
  scale: 1;
  transition: all 0.3s;
  position: relative; /* Set position relative to allow overlay positioning */
}

.img-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ); /* Adjust the color and opacity as needed */
  color: white;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.f-img:hover .img-overlay,
.img-24:hover .img-overlay,
.img-35:hover .img-overlay {
  opacity: 1;
}
#p {
  color: #000;
  text-decoration: none;
  scale: 1;
  transition: all 0.3s;
  margin-top: 3%;
}
#p:hover {
  opacity: 0.5;
  transition: all 0.3s;
}
.contacts-p {
  font-size: calc(var(--size) / 1.4);
}
.burger-icon {
  display: none;
  width: 8%;
}
.burger-menu {
  height: 60vh;
  position: fixed;
  top: 5%;
  right: 0%;
  background: rgba(0, 0, 0, 0.3);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(15px);
  width: 100vw;
  display: none;
  place-items: center;
  z-index: 1001;
  transform: translateY(-700px);
  transition: all 0.5s;
}
.show {
  transform: translateY(0px);
  transition: all 0.5s;
}
.burger-links {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 1000px) {
  .img-24 {
    width: 43.5%;
  }
  .img-35 {
    width: 43.5%;
  }
}
@media screen and (max-width: 500px) {
  .welcome-block {
    background-size: cover;
    height: 40vh;
  }
  .contact-row-h {
    font-size: calc(var(--size) * 1.3);
  }
  .contacts-h {
    font-size: calc(var(--size) * 1.3);
  }
  .swiper-mat img {
    margin-bottom: 35%;
  }
  .kovel-wrap {
    margin-left: 5%;
  }
  .toTop {
    width: 10%;
  }
  .ask {
    width: 10%;
  }
  .whyUs-row-h {
    font-size: calc(var(--size) * 2.1);
  }
  .contact-input {
    height: 23px;
    border: 1px solid;
    margin-bottom: 5%;
  }
  .nav-links {
    display: none;
  }
  .burger-icon {
    display: block;
  }
  .navbar-wrapper {
    padding: 2% 0%;
  }
  .nav-logo {
    width: 30%;
  }
  .nav-a {
    color: #fff;
    margin-right: 0px;
    margin-bottom: 15%;
    font-size: calc(var(--size) * 1.4);
  }
  .burger-menu {
    display: grid;
  }
  .swiper-pagination {
    display: none;
  }
  .material-h {
    margin-bottom: 10%;
    margin-top: 5%;
  }
  .material-p {
    margin-top: 10%;
  }
}
.img-container {
  position: relative;
  display: inline-block;
}

.img-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ); /* You can adjust the color and opacity here */
  color: white;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0; /* Start with opacity 0 to hide the overlay */
  transition: opacity 0.3s ease;
}

.img-container:hover .img-overlay {
  opacity: 1; /* Show the overlay on hover */
}
.map {
  width: 135%;
  padding-right: 8%;
}
.catalog-block {
  display: grid;
  place-items: center;
  padding: 5% 5%;
}
.catalog-h {
  font-size: calc(var(--size) * 1.4);
  font-weight: 500;
  margin-bottom: 5%;
}
.slider-div {
  display: flex;
  justify-content: center;
}
.catalog {
  margin-right: 3%;
}
/* VerticalSlider.css */
.slider-container {
  /* max-width: 300px; Set your desired slider width */
  margin: 0 auto;
}
.slide img {
  width: 35%;
  object-fit: cover;
  border-radius: 4px;
}
.type {
  font-size: calc(var(--size) / 1.4);
}

.over {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 3%;
  right: 15%;
  width: 80%;
  height: 94%;
  background: rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(2.5px);
  opacity: 0;
  transition: all 0.3s;
  flex-direction: column;
}
.over-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.over-wrap a {
  color: #fff;
  font-weight: 700;
  font-size: calc(var(--size) / 1.2);
  text-decoration: none;
}
.over-wrap a:hover {
  opacity: 0.6;
}
.over p {
  color: #fff;
  font-weight: 700;
  font-size: calc(var(--size) / 1.1);
  border-bottom: 2px solid #ff0014;
}
.img-24 {
  width: 180.5%;
  position: relative;
}
.img-35 {
  width: 180.5%;
  position: relative;
}
.rel {
  position: relative;
}
.f-img {
  width: 90%;
}
.rel:hover .over {
  opacity: 1;
  transition: all 0.3s;
}
.r {
  margin-left: 70%;
}
.l {
  margin-left: -15%;
}
.over-2 {
  width: 160%;
  right: -56%;
}
.over-4 {
  width: 160%;
  right: -141%;
}
.over-3 {
  width: 160%;
  right: -55%;
}
.over-5 {
  width: 160%;
  right: -141%;
}

@media screen and (max-width: 995px) {
  .img-24 {
    width: 90%;
  }
  .img-35 {
    width: 90%;
  }
  .f-img {
    width: 90%;
  }
  .r {
    margin-left: 0%;
  }
  .l {
    margin-left: 0%;
  }
  .over-2 {
    width: 81%;
    right: 14%;
    top: 4%;
    height: 90%;
  }
  .over-4 {
    width: 81%;
    right: 14%;
    top: 4%;
    height: 90%;
  }
  .over-3 {
    width: 81%;
    right: 14%;
    top: 4%;
    height: 90%;
  }
  .over-5 {
    width: 81%;
    right: 14%;
    top: 4%;
    height: 90%;
  }
}
#wisn {
  cursor: auto;
  pointer-events: none;
}
@media screen and (max-width: 500px) {
  .over p {
    font-size: calc(var(--size) / 1.6);
  }
  .contact-row-cont {
    flex-direction: column;
    align-items: center;
  }
  .map {
    width: 100%;
    padding-right: 0%;
    margin-top: 5%;
    margin-bottom: 5%;
  }
  .mp {
    margin-top: 7%;
  }
  .catalog-h {
    margin-bottom: 10%;
  }
  .over-wrap a {
    font-size: calc(var(--size) / 1.8);
  }
}
.cat-img {
  width: 85%;
  border-radius: 10px;
}
.slide-cat {
  display: grid;
  place-items: center;
  position: relative;
}
.cat-over {
  position: absolute;
  top: 2%;
  left: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(67, 67, 67, 0.6);
  color: #fff;
  padding: 0.5% 3%;
}
.cat-over p {
  font-size: calc(var(--size) / 1.1);
  padding-top: 3%;
}
.splide__arrow--prev {
  margin-left: 4%;
}
.splide__arrow--next {
  margin-right: 4%;
}
.slide-wrap {
  display: grid;
  place-items: center;
  cursor: pointer;
  scale: 1;
  transition: all 0.3s;
}
.slide-wrap:hover {
  opacity: 0.7;
  transition: all 0.3s;
}
.logo-imgs {
  cursor: pointer;
  scale: 1;
  transition: all 0.3s;
}
.collage-wrapper {
  display: flex;
  justify-content: center;
}
#main-img {
  width: 100%;
  height: 98%;
}
.swiper-mat img {
  cursor: pointer;
  scale: 1;
  transition: all 0.3s;
  padding: 2%;
}
.swiper-mat img:hover {
  opacity: 0.8;
  transition: all 0.3s;
}
.image-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.image-modal-content {
  width: 20%;
}

.image-modal img {
  width: 100%;
  height: auto;
  display: block;
  margin: auto;
  cursor: pointer;
}

.logo-imgs:hover {
  opacity: 0.7;
  transition: all 0.3s;
}
.animated-element {
  opacity: 0;
  transition: opacity 1.2s;
}
.animated-element.animate {
  opacity: 1;
}
@media screen and (max-width: 800px) {
  .map {
    width: 110%;
  }
}
@media screen and (max-width: 500px) {
  .map {
    width: 90%;
  }
  #p {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .modal-tel-input {
    padding: 1% 5%;
  }
  .modal-content {
    margin: 5%;
  }
  .contacts-tels {
    flex-direction: column;
  }
  .contacts-p-wrap {
    margin: 5% 0;
  }
}
@media screen and (max-width: 600px) {
  .slider-div {
    flex-direction: column;
    align-items: center;
  }
  .splide__arrow {
    width: 22px;
    height: 22px;
  }
  .logo-slider {
    display: grid;
    place-items: center;
    margin-top: 3%;
    padding: 0% 10%;
  }
  .slide {
    display: contents;
  }
  .slide img {
    width: 15%;
    margin-right: 5%;
  }
}
